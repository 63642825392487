import React from 'react';
import './PrivacyPolicy.css'; 

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <h1>Daily Grids Policies</h1>
      <p><strong>Last Updated:</strong> 5th Dec, 2024</p>
      <p>Welcome to Daily Grids. Your privacy is important to us, and we are committed to protecting the personal information you share with us. This Privacy Policy outlines how we collect, use, and safeguard your data when you use our services.</p>

      <h2>1. Information We Collect</h2>
      <h3>1.1. Personal Information</h3>
      <p>This includes:</p>
      <ul>
        <li>Account Details: Name, email address, password, and profile information provided during registration.</li>
        <li>Contact Information: Any information you provide when contacting support.</li>
      </ul>

      <h3>1.2. Usage Data</h3>
      <p>This includes:</p>
      <ul>
        <li>Activity Data: Pages visited, time spent on the platform, features used, and interactions.</li>
      </ul>

      <h3>1.3. Cookies and Tracking Technologies</h3>
      <p>We use cookies and similar tracking technologies to enhance user experience and collect additional usage data.</p>

      <h2>2. How We Use Your Information</h2>
      <ul>
        <li>Provide and maintain our services.</li>
        <li>Personalize user experiences.</li>
        <li>Respond to inquiries and provide customer support.</li>
        <li>Improve our platform through analytics and feedback.</li>
        <li>Ensure security and prevent fraud.</li>
        <li>Send notifications or updates (you may opt out of non-essential communications).</li>
      </ul>

      <h2>3. Data Sharing and Third-Party Services</h2>
      <p>We may share your data with trusted third parties to facilitate our services. These include:</p>
      <h3>3.1. Analytics Providers</h3>
      <p>Google Analytics: To track usage patterns and improve the platform.</p>
      <h3>3.2. Hosting Providers</h3>
      <p>Render/MongoDB: To host our platform and store data securely.</p>
      <h3>3.3. Communication Services</h3>
      <p>Google Mails: For sending emails and notifications.</p>
      <h3>3.4. Payment Processors</h3>
      <p>PayU Payment Gateway: To handle transactions securely.</p>
      <h3>3.5. Legal Compliance</h3>
      <p>We may disclose information if required by law or to protect our rights and comply with legal obligations.</p>

      <h2>4. Data Retention</h2>
      <p>We retain your personal data as long as necessary to fulfil the purposes outlined in this Privacy Policy or comply with legal requirements. Upon your request or account deletion, we will delete or anonymize your data unless it is required to be retained by law.</p>

      <h2>5. User Rights</h2>
      <p>As a user, you have the following rights:</p>
      <ul>
        <li>Access: Request a copy of your personal data.</li>
        <li>Correction: Update or correct your information.</li>
        <li>Deletion: Request deletion of your data.</li>
        <li>Restriction: Limit how your data is processed.</li>
        <li>Opt-Out: Opt out of data collection for analytics or marketing communications.</li>
      </ul>
      <p>To exercise any of these rights, please contact us at <a href="mailto:support@dailygrids.com">support@dailygrids.com</a>.</p>

      <h2>6. Security</h2>
      <p>We implement industry-standard security measures to protect your data. However, no method of transmission or storage is entirely secure. We encourage you to use strong passwords and protect your account credentials.</p>

      <h2>7. International Data Transfers</h2>
      <p>If you access Daily Grids from outside India, please note that your data may be transferred to and processed in India, where data protection laws may differ. We ensure appropriate safeguards are in place to protect your information.</p>

      <h2>8. Children’s Privacy</h2>
      <p>Daily Grids does not knowingly track any personal data from individuals under 13 years of age. The data collected for user authentication is mandatory, and no target marketing is done towards the individuals using the data.</p>

      <h2>9. Changes to This Privacy Policy</h2>
      <p>We may update this Privacy Policy periodically to reflect changes in our practices or for legal reasons. The "Last Updated" date at the top of this page indicates the most recent revision. We encourage you to review this policy regularly.</p>

      <h2>10. Contact Us</h2>
      <p>If you have any questions or concerns about this Privacy Policy or your data, please contact us:</p>
      <ul>
        <li>Email: <a href="mailto:support@dailygrids.com">support@dailygrids.com</a></li>
        <li>Address: 96 Colonelganj, Prayagraj, UP, PIN - 211002</li>
      </ul>

      <p>Thank you for trusting Daily Grids. Your privacy is our priority.</p>
    </div>
  );
};

export default PrivacyPolicy;
