// src/App.js
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; 
import WaterWave from 'react-water-wave';
import Navbar from './components/Navbar';
import HomePage from './components/HomePage';
import  ReportProblem from './components/ReportProblem'; 
import ContactUs from './components/ContactUs'; 
import PrivacyPolicy from './components/PrivacyPolicy'; 
import ResetPassword from './components/ResetPassword'; 
import logo from './components/assets/logo.png'; 


import bgImage from './components/assets/bg.jpg';

import './App.css';

function App() {
  useEffect(() => {
    document.title = "Daily Grids"; 
  }, []);
  return (
    <Router> 
      <WaterWave
        imageUrl={bgImage}
        style={{ width: '100%', height: '100vh', backgroundSize: 'cover' }}
      >
        {() => (
          <div className="App">
            <Navbar />
            <Routes> 
              <Route path="/" element={<HomePage />} /> 
              <Route path="/contact-us" element={<ContactUs />} /> 
              <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} /> 
              <Route path="/report-problem" element={<ReportProblem />} /> 
              <Route path="/ResetPassword" element={<ResetPassword />} /> 
            </Routes>
            
          </div>
        )}
      </WaterWave>
    </Router>
  );
}

export default App;
