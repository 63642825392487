import React from 'react';
import './Features.css';
import { Link } from 'react-router-dom';  

function BenefitsSection() {
  return (
    <section className="benefits-section">
      <h2 className="benefits-heading">Why Choose Daily Grids?</h2>
      <p className="benefits-intro">
        With DailyGrids, you’re not just managing tasks – you’re creating a more organized, focused, and balanced life. Here’s why users love it:
      </p>
      <div className="benefits-list">
        <div className="benefit-item">
          <h3>Versatile & Easy to Use</h3>
          <p>Designed to fit any lifestyle, DailyGrids adapts to anyone’s schedule planner, from students needing a study planner to professionals organizing their digital calendar planner.</p>
        </div>
        <div className="benefit-item">
          <h3>Personalized Insights</h3>
          <p>Get actionable insights to help you work smarter, stay on track, and meet your goals faster. Whether you're planning your daily tasks or setting long-term objectives, DailyGrids ensures you're always moving forward.</p>
        </div>
        <div className="benefit-item">
          <h3>Stay Accountable</h3>
          <p>Track your progress with features that help you manage both short-term tasks and long-term goals. Receive reminders to keep you focused on what's important and ensure you meet your objectives with ease.</p>
        </div>
        <div className="benefit-item">
          <h3>All Your Tools in One Place</h3>
          <p>From notes to timers, and even goal-setting tools, DailyGrids is the only planner you need to stay organized. Whether you're using a digital planner or just need a quick timetable planner, everything you need is here.</p>
        </div>
      </div>
      <Link to="/PrivacyPolicy"> <p className = 'privacy'>Our Policies</p></Link> 
    </section>
    
  );
}

export default BenefitsSection;
