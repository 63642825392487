// src/components/IntroSection.js
import React from 'react';
import './IntroSection.css';

function IntroSection() {
    return (
        <section className="intro">
            <h1>Achieve More Every Day with Daily Grids</h1>
            <p>Life can be hectic, and staying on top of your tasks, projects, and goals can feel overwhelming. That's where DailyGrids comes in. Our versatile planner helps you organize your schedule, track productivity, and stay focused on what matters most. Whether you're looking for a daily planner, weekly planner, monthly planner, or a study planner, DailyGrids is designed to adapt to your unique needs, ensuring you stay productive every day.</p>
                <div className="buttons">
                {/* <button className="btn-primary">Download App</button> */}
                <button className="btn-secondary" onClick={() => window.location.href = 'https://app.dailygrids.com/'} >Continue to Web App</button>
            </div>
        </section>
    );
}

export default IntroSection;
